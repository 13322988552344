import logo from './logo.svg';
import './App.css';

import React, { useState }  from 'react';
import { Empty, Button, Typography, Highlight } from '@douyinfe/semi-ui';
import { IllustrationConstruction, IllustrationConstructionDark } from '@douyinfe/semi-illustrations';


let if_mobile = false
let if_desktop = false
//判断客户端类型
const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
let device_type = mobile ? 'mobile' : 'desktop';
console.log('dtype:',device_type)
const body = document.body;
if(device_type==='desktop'){
    if_mobile = true
    //if_desktop = true
    body.setAttribute('theme-mode', 'dark');
    setTimeout(function() {
        if(window.voiceflow){
            window.voiceflow.chat.hide();
        }else{
            window.location.reload()
        }
    }, 300);
}else{
    if_mobile = true
    body.removeAttribute('theme-mode');
    setTimeout(function() {
        if(window.voiceflow){
            window.voiceflow.chat.hide();
        }else{
            window.location.reload()
        }
    }, 300);
}

const { Paragraph, Title, Text } = Typography;
//主页面
const App = () => {

    return (
        <>
            {if_desktop && (
                <>
                    <Empty style={{marginTop:140, whiteSpace: 'pre-line'}}
                        image={<IllustrationConstruction style={{ width: 150, height: 150 }} />}
                        darkModeImage={<IllustrationConstructionDark style={{ width: 150, height: 150 }} />}
                        title={'欢迎来到思律Hi-Law内测版'}
                        description="PC端正在开发中，请使用手机扫码体验～"
                    />
                    <img src="%PUBLIC_URL%/../mobile.png" style={{ width: 150, height: 150,marginLeft: 'auto', marginRight: 'auto',display: 'block',marginTop:32}} />
                </>
            )}
            {if_mobile && (
                <>
                    <Empty style={{marginTop:90}}
                        image={<IllustrationConstruction style={{ width: 150, height: 150 }} />}
                        darkModeImage={<IllustrationConstructionDark style={{ width: 150, height: 150 }} />}
                        title="欢迎来到思律Hi-Law内测版"
                    >
                        <Title heading={5}>支持的功能</Title>
                        <Text type="secondary">1. 判断是否能获得离职补偿金</Text>
                        <br/>
                        <Text type="secondary">2. 计算具体补偿金数额</Text>
                        <br/>
                        <Text type="secondary">3. 提供维权策略</Text>
                        <br/>
                        <Text type="secondary">4. 生成仲裁申请书</Text>
                        <br/>
                        <Text type="secondary">5. 对接律师</Text>
                        <br/>
                        <Text type="warning">如果您回到本页面，说明网络不稳定，</Text>
                        <br/>
                        <Text type="warning">请重新开始即可，希望能帮到您。</Text>
                        <br/>
                        <div style={{width: '60%',margin:'auto',marginTop:32}}>
                            <Button block style={{ padding: '6px 24px',display: 'block' }} size='large' theme="solid" type="primary" onClick={() => {
                                    window.voiceflow.chat.show();
                                    window.voiceflow.chat.open()
                                    let onload_success = false
                                    window.addEventListener('message', (event) => {
                                        let data = JSON.parse(event.data)
                                        console.log('event.data:',data);
                                        if(data.type==="voiceflow:save_session"){
                                            onload_success = true
                                        }
                                      }, false);
                                      setTimeout(function() {
                                        if(onload_success!==true){
                                            window.location.reload()
                                        }
                                      }, 1500);
                                }}>
                                开始咨询
                            </Button>
                        </div>
                    </Empty>
                    
                </>
            )}
        </>
    );
};


export default App;
